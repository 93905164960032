// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    hmr: false,
    frontendUrl: 'https://dev.optimaventurez.com/yiiapp/frontend/web/',

    yiiUrl: 'https://dev.optimaventurez.com/yiiapp/backend/web/',
    // yiiUrl: 'https://dev.optima-crm.com/yiiapp/backend/web/',

    // mailBoxUrl: 'https://crm1.optimaventurez.com/optima-mailbox/web/index.php?r=',
    mailBoxUrl: 'https://crm.optimaventurez.com/mailbox/optimacrm-uae-mailbox/web/index.php?r=',

    apiUrl: 'https://dev.optimaventurez.com/v3/',
    // apiUrl: 'https://dev.optima-crm.com/v3/',

    cdnUrl: 'https://crm.optimaventurez.com/images/',
    remoteCdnUrl: 'https://crm.optimaventurez.com/images/',
    rootUrl: 'https://dev.optimaventurez.com/yiiapp/',
    socketUrl: 'https://dev.optimaventurez.com',
    stripeKey: 'pk_test_yDZWyg4T6T8r4zrelFQlYln6',
    appUrl: 'https://dev.optimaventurez.com/',
    // stripeKey: 'pk_test_yDZWyg4T6T8r4zrelFQlYln6',
    sentry: {
        dsn: 'https://5ba5b5d416814763802cd03d385a210b@o888757.ingest.sentry.io/5840679',
        origins: ['localhost'],
    },
    s3Url: 'https://optimacrmbucket.s3.eu-west-3.amazonaws.com/',
    commonAgency: '56b06e4d2bc1d95f60cedef2',
    roAgency : '56af07692bc1d9325acedef2',
    roCostaBlancaAgency : '648809e1ecbc080c710a4aa8',
    roCostaDelSolAgency : '659fbe57a558621ef10034ea',
    fcsAgency: '596dbb3f7219354c738b4567',
    immoAgency: '6110fa9b8334050aac21e779',
    auctionOffice: '61cc1d01ce179b7079176464',
    casafariAgency: '64253e22c89dd014a300be6e',
    casafariPortugalAgency: '6583ff9f56bfbac46001684b',
    ibizaAgency: '5abe15f48280de6f6150c895',
    zpAgency: "5b3cdaee8280de32e53cc48d",
    jvAgency: "5d1dc667b15da7752b11664e",
    bayutXmlFormat: '6696d2210622712e08074c42',
    propertyFinderXmlFormat: '6641bcbafdb744b60d0ad7af'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
