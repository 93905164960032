import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MlsNotificationsComponent } from 'app/main/authentication/agencies/create-agency/tabs/mls-sharing/dialog/mls-notifications/mls-notifications.component';
import { AuthenticationService, UserService } from 'app/services';
import { MlsSharingService } from 'app/services/mls-sharing.service';
import { AppProviders } from 'app/_helpers';
import { BaseService } from 'app/_helpers/base/base.service';
import { identity, pickBy } from 'lodash';
import { Subject } from 'rxjs';
import { WebsocketService } from '../../../services/websocket.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { startWith, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NotificationComponent } from './dialog/notification/notification.component';
import { MeetingCreateModalComponent } from 'app/main/activities/meetings/meeting-create-modal/meeting-create-modal.component';
import { TasksCreateModalComponent } from 'app/main/activities/tasks/tasks-create-modal/tasks-create-modal.component';
import { ViewingToursModalComponent } from 'app/main/activities/viewingTours/viewing-tours-modal/viewing-tours-modal.component';
import { TimeLineDataService } from 'app/components/time-line/time-line-data.service';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit, OnDestroy {
    date: Date;
    events: any[];
    currentUser: any;
    languages: any;
    user: FormGroup;
    id: any;
    agency: any;
    iCal: any;

    public currentLang: any;
    // Environment 
    public env = environment;

    // tslint:disable-next-line: variable-name
    ical_url: FormControl = new FormControl('');

    // Private
    private _unsubscribeAll: Subject<any>;
    newRequests: any;

    // Notifications

    public unReadCount: string | number = 0;

    private page = 1;

    public notifications = [];

    private data = {} as any;
    public officeData: any;
    cbSubAgency = false;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _snackBar: MatSnackBar,
        private _appProviders: AppProviders,
        private _authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private _userService: UserService,
        private _translateService: TranslateService,
        private router: Router,
        private _mlsSharingService: MlsSharingService,
        private _matDialog: MatDialog,
        private _gService: BaseService,
        private socket: WebsocketService,
        private toaster: ToastrService,
        private _timeLineDataService: TimeLineDataService

    ) {
        this.id = this._authenticationService.currentUserValue ? _authenticationService.currentUserValue._id : null;
        this.agency = this._authenticationService.currentUserValue ? _authenticationService.currentUserValue.user_agency : null;
        this._translateService.onLangChange
            .pipe(startWith(''))
            .subscribe(() => {
                this.currentLang = this._translateService.currentLang;
            });
        this.languages = this._appProviders.siteLanguages;
        _userService.getById(this.id).subscribe((data: any) => {
            this.currentUser = data;
        });
        // Set the defaults
        this.date = new Date();

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.iCal = `${environment.frontendUrl}index.php?r=ical/index&user=${this.id}`;
        let currentAgency = this._authenticationService.currentAgencyValue;
        this.officeData = this._authenticationService.currentOfficeValue;
        if (!this.officeData?.master_office && this.officeData?.is_cb && currentAgency.brochures_only) {
            this.cbSubAgency = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.socket.listen('notification')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.notifications.unshift(res);
                this.socket.emit('unReadNotificationCount', { userId: this._authenticationService.currentUserValue._id });
                // this.socket.emit('getAllNotifications', { userId: this._authenticationService.currentUserValue._id });
                // let message = '';
                // if (res.type === 'post' && res.status === 'create') {
                //     message = `${res.created_name} posted in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'updated') {
                //     message = `${res.created_name} updated post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'deleted') {
                //     message = `${res.created_name} deleted post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'report') {
                //     message = `${res.created_name} report your post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'like') {
                //     message = `${res.created_name} liked your post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'unlike') {
                //     message = `${res.created_name} unlike your post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && res.status === 'comment') {
                //     message = `${res.created_name} commented on your post in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'post' && (res.status === 'commentReply' || res.status === 'replyOfReply')) {
                //     message = `${res.created_name} replied on your comment in ${res.board.value[this.currentLang]} timeline!`;
                // } else if (res.type === 'meeting' && res.status === 'create') {
                //     message = `${res.created_name} created Meeting!`;
                // } else if (res.type === 'meeting' && res.status === 'update') {
                //     message = `${res.created_name} updated Meeting!`;
                // } else if (res.type === 'task' && res.status === 'create') {
                //     message = `${res.created_name} created Task!`;
                // } else if (res.type === 'task' && res.status === 'update') {
                //     message = `${res.created_name} ${this._translateService.instant('updated Task!')}`;
                // } else if (res.type === 'viewing' && res.status === 'create') {
                //     message = `${res.created_name} assigne you viewing Tour!`;
                // } else if (res.type === 'viewing' && res.status === 'update') {
                //     message = `${res.created_name} updated viewing tour assigned to you!`;
                // }
                // this.toaster.success(message, this._translateService.instant('Notification'), { timeOut: 2000, progressBar: true });
            });
        this.socket.listen('NotificationCount')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this.unReadCount = res;
                if(res > 99){
                    this.unReadCount = "99+"
                }
                this.socket.emit('getAllNotifications', { userId: this._authenticationService.currentUserValue._id, remove_pervious: true });

                // if (this.unReadCount > 0) {
                //     let message = `${this.unReadCount} Unread notifications`;
                //     this.toaster.success(message, this._translateService.instant('Notification'), { timeOut: 2000, progressBar: true });
                // }
            });
        this.socket.listen('getAllNotifications')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if(res.remove_pervious)
                    this.notifications = [];
                res.notifications.map(val => this.notifications.push(val));
            });
        this.socket.listen('readNotification')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                // const index = this.notifications.findIndex((ele) =>  ele._id === res.notification._id );
                // console.log(index);
                this.notifications[this.notifications.findIndex((ele) => ele._id === res.notification._id)].read_status = true;
                this.socket.emit('unReadNotificationCount', { userId: this._authenticationService.currentUserValue._id });

            });
        this.socket.listen('exception')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this._gService.errorMessage(res, 'Something Failed!');
            });
        this.socket.listen('disconnected')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                this._gService.errorMessage(res, 'Something Failed!');
            });
        this.getActivities();
        this.user = this.formBuilder.group({
            default_lang: (''),
            account_view: (''),
            owner_view: (''),
            _id: (this.id),
            agency: (this.agency),
            ical_url: ('')
        });
        this._userService.getById(this.id).subscribe((data: any) => {
            if (data.ical_url) {
                this.user.get('ical_url').setValue(data.ical_url);
            }
        });

        this._mlsSharingService.getNewRequests()
            .subscribe((data: any) => {
                this.newRequests = data;
            });

    }

    exportiCal(): void {
        this._snackBar.open(this._translateService.instant('Copied successfully'), this._translateService.instant('Close'), {
            duration: 2000,
        });
    }

    onImgError(event) {
        event.target.src = environment.remoteCdnUrl + '/static/users/profile.png'
        //Do other stuff with the event.target
    }

    updateUser(value?: any, check?: any): void {
        if (check === 'default_lang') {
            this.user.get('default_lang').setValue(value);
        } else if (check === 'account_view') {
            this.user.get('account_view').setValue(value);
        } else if (check === 'owner_view') {
            this.user.get('owner_view').setValue(value);
        }

        this._userService.update(pickBy(this.user.value, identity)).subscribe((data: any) => {
            this._userService.getById(this.id).subscribe((userData: any) => {
                this.currentUser = userData;
            });
            if (check && value) {
                this.openSnackBar(this._translateService.instant('Login again to update settings'), this._translateService.instant('Close'));
            } else {
                this.openSnackBar(this._translateService.instant('Update successful'), this._translateService.instant('Close'));
            }
        });
    }

    // Infinite Scroll Method

    public onScroll(): void {
        this.page++;
        this.socket.emit('getAllNotifications', { userId: this._authenticationService.currentUserValue._id, page: this.page });
    }


    // Read Notification 

    public readNotification(notification): void {
        this.socket.emit('readNotification', { userId: this._authenticationService.currentUserValue._id, notification: notification });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getActivities(): void {
        this._gService.get('dashboard/user-todays', 'Yii').subscribe((data: any) => {
            this.events = data;
        });
    }

    viewActivity(id: any, event: any): void {
        if (event === 'meeting') {
            this.router.navigate(['/activities/meetings/view', id]);
        }
        if (event === 'task') {
            this.router.navigate(['/activities/tasks/view', id]);
        }

    }

    changeStatus(event: any): void {
        this._gService.get(`activities/update-status?id=${event.id}&status=Closed`, 'Yii').subscribe((data: any) => {
            this.openSnackBar(this._translateService.instant('Status closed'), this._translateService.instant('Close'));
            this.getActivities();
        });
    }

    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }

    openSharingRequests(): void {
        const dialogRef = this._matDialog.open(MlsNotificationsComponent, {
            panelClass: 'mls-notifications',
            data: this.newRequests
        });
        dialogRef.afterClosed()
            .subscribe(result => {
                this.newRequests = result;
            });
    }

    // Notification Dialog
    openNotification(index: number): void {

        const notification = this.notifications[index];
        if (notification.type === 'post') {
            if (notification.status === 'create' || notification.status === 'updated' || notification.status === 'like'
                || notification.status === 'unlike' || notification.status === 'report') {
                this.getPost(notification.model_id);

            }
            if (notification.status === 'comment' || notification.status === 'commentReply' || notification.status === 'replyOfReply') {
                this.getComment(notification.model_id, notification.status);
            }
        } else if (notification.type === 'viewing') {
            const dialogRef = this._matDialog.open(ViewingToursModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    _id: notification.model_id,
                    relatedTo: 'update',

                }
            });
            dialogRef.afterClosed()
                .subscribe(res => {
                    this.data = {};
                });
        } else if (notification.type === 'task') {
            const dialogRef = this._matDialog.open(TasksCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    _id: notification.model_id
                }
            });
            dialogRef.afterClosed()
                .subscribe(res => {
                    this.data = {};
                });
        } else if (notification.type === 'meeting') {
            const dialogRef = this._matDialog.open(MeetingCreateModalComponent, {
                panelClass: 'event-form-dialog',
                data: {
                    _id: notification.model_id
                }
            });
            dialogRef.afterClosed()
                .subscribe(res => {
                    this.data = {};
                });
            //    this.openDialog();
        } else if(notification.type === 'Property creation') {
            this.router.navigate(['/commercials/view/', notification.model_id]);
        }else if(notification.type === 'lead whatsapp message'){
            this._timeLineDataService.setDefaultTab('Whatsapp')
            this.router.navigate([`/master-data/accounts/update/${notification.model_id}/notes`]);
        }
        else if(notification.type === 'whatsapp quality update') {
            this.router.navigate([`/commercial-reporting/whatsapp-reporting`]);
        }
    }

    private getPost(postId): any {
        this._gService.get('mp/board/message/get/' + postId, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    this.data = res.data;
                    this.data.type = 'post';
                    this.openDialog();
                } else {
                    this._gService.errorMessage('Something Failed!', 'Error');
                }

            });
    }

    private getComment(commentId, status): any {
        this._gService.get('mp/boardmessage/comments/get/' + commentId, 'nodejs')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res.status === true) {
                    if (status === 'comment') {
                        this.getPost(res.data.message);
                    }
                    if (status === 'commentReply' || status === 'replyOfReply') {
                        this.getComment(res.data.parent_comment, 'comment');
                    }
                } else {
                    this._gService.errorMessage('Something Failed!', 'Error');
                }
            });
    }

    private openDialog(): any {
        const dialogRef = this._matDialog.open(NotificationComponent, {
            panelClass: 'app-notifications',
            data: this.data
        });
        dialogRef.afterClosed()
            .subscribe(res => {
                this.data = {};
            });
    }
    // remove undefined from title
    public getTitle(title) {
       return title.replace(/undefined/g, '');
    }

    public navigateToAllNotifications(){
        this.router.navigate([`/all-notifications`]);
    }
}
