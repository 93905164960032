import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappMessageComponent } from './whatsapp-message/whatsapp-message.component';
import { BaseService } from 'app/_helpers/base/base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[whatsapp]'
})
export class WhatsappDirective {

  @Input() selectedIds = [];
  @Input() model = 'accounts';
  currentAgency:any
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public _matDialog: MatDialog,
    public _baseService: BaseService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private toaster: ToastrService,
  ) { 
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
  }

  @HostListener('click') onClick(): void {
    this.currentAgency = this._authenticationService.currentAgencyValue;
    
    if(this.currentAgency.wa_disable_messages){
      this.toaster.warning(this._translateService.instant('Whatsapp messaging is disabled!'), this._translateService.instant('Whatsapp'), { timeOut: 7000, progressBar: true });
      return;
    }
    const dialogRef = this._matDialog.open(WhatsappMessageComponent, {
        panelClass: 'whatsapp-message-dialog',
        data: {
          ids: this.selectedIds
        }
    });
    dialogRef.afterClosed()
      .subscribe(response => {
        if (response) {
          let postData = {};
          postData = {
            'items': this.selectedIds,
            'type': 'template', // message type can be text or template
            'message': response,
            'model': this.model
          }
          this._baseService.post('external-service/wa-message-bulk', postData, 'nodejs').subscribe((data: any) => {
            this._snackBar.open(this._translateService.instant(data?.mesage), this._translateService.instant('Close'), {
                  duration: 5000,
              });
          });
        }
      });
  }

}
